import React from 'react';
import MathRunnerGame from './components/MathRunnerGame';

function App() {
  return (
    <div className="App">
      <MathRunnerGame />
    </div>
  );
}

export default App;