import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { initGA, logPageView, logEvent } from '../utils/analytics';
import playerSpriteSheet from '../assets/images/player-sprite-sheet.png';
import backgroundMusic from '../assets/audio/background-music.mp3';
import tapSound from '../assets/audio/tap-sound.mp3';
import correctSound from '../assets/audio/correct-sound.mp3';
import wrongSound from '../assets/audio/wrong-sound.mp3';
import ScrollingTrackBackground from './ScrollingTrackBackground';
//import GoogleAd from './GoogleAd.js';
import emailIcon from '../assets/images/emailicon.png';
import twitterIcon from '../assets/images/twittericon.png';

const formatNumber = (num) => {
  if (typeof num !== 'number') {
    console.warn('formatNumber received non-number input:', num);
    return 'N/A'; // or return some default value
  }
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(1);
  }
};

const TrackLines = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {[15, 38, 62, 85].map((position, index) => (
        <div
          key={index}
          className="absolute top-0 bottom-0 border-l border-dashed border-white opacity-30"
          style={{ left: `${position}%` }}
        />
      ))}
    </div>
  );
};

const MathRunnerGame = () => {
  useEffect(() => {
    initGA('G-8DTQKT8MFH'); // Replace with your Measurement ID
    logPageView();
  }, []);

  const [score, setScore] = useState(0);
  const [keys, setKeys] = useState({ left: false, right: false });
  const [playerPosition, setPlayerPosition] = useState(50);
  const [gameObjects, setGameObjects] = useState([]);
  const [equation, setEquation] = useState({ num1: null, operator: null, num2: null, answer: null });
  const [gameOver, setGameOver] = useState(false);
  const [selectionStage, setSelectionStage] = useState(0);
  const [moveSpeed, setMoveSpeed] = useState(2);
  const [difficulty, setDifficulty] = useState(1);
  //const [gameTime, setGameTime] = useState(0);
  //const [missedSelection, setMissedSelection] = useState(false);
  const [spriteFrame, setSpriteFrame] = useState(0);
  const [lastEquation, setLastEquation] = useState({ num1: null, operator: null, num2: null, correctAnswer: null, userAnswer: null });

  const gameAreaRef = useRef(null);
  const touchStartX = useRef(null);
  const animationFrameRef = useRef();
  const lastUpdateTimeRef = useRef(0);
  const audioContext = useRef(null);
  const backgroundMusicRef = useRef(null);
  const tapSoundRef = useRef(null);
  const correctSoundRef = useRef(null);
  const wrongSoundRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const isMusicPlaying = useRef(false);


  const SPRITE_WIDTH = 32;
  const SPRITE_HEIGHT = 32;
  const BOTTOM_ROW_Y = 96;
  const NUM_FRAMES = 8;
  const SPRITE_SCALE = 8;
  const [gameDimensions, setGameDimensions] = useState({ width: 0, height: 0 });
  //const gameWidth = window.innerWidth;
  //const gameHeight = window.innerHeight;
  const PLAYER_RELATIVE_Y = 0.8; // Player at 80% of screen height
  const COLLISION_THRESHOLD = 0.7; // Collision at 70% of screen height

  useEffect(() => {
    const updateDimensions = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      const aspectRatio = 9 / 16;
      const maxWidth = 520; // Maximum width for desktop
      const maxHeight = 800; // Maximum height for desktop

      // Check if it's a desktop or mobile device
      const isDesktopDevice = window.innerWidth > 1024; // You can adjust this threshold
      setIsDesktop(isDesktopDevice);

      let width, height;

      if (isDesktopDevice) {
        // For desktop, maintain the aspect ratio within max dimensions
        if (maxHeight / maxWidth > aspectRatio) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      } else {
        // For mobile and tablets, fit to screen while trying to maintain aspect ratio
        if (window.innerWidth / window.innerHeight > aspectRatio) {
          height = window.innerHeight;
          width = height * aspectRatio;
        } else {
          width = window.innerWidth;
          height = width / aspectRatio;
        }
        // Ensure it doesn't exceed the screen size
        width = Math.min(width, window.innerWidth);
        height = Math.min(height, window.innerHeight);
      }

      setGameDimensions({ width, height });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);



  const columns = useMemo(() => [15, 38, 62, 85], []);

  const roundToWholeNumber = (num) => Math.round(num);
  //const roundToOneDecimal = (num) => Math.round(num * 10) / 10;

  const safeCalculate = useCallback((num1, operator, num2) => {
    let result;
    switch(operator) {
      case '+': result = num1 + num2; break;
      case '-': result = num1 - num2; break;
      case '*': result = num1 * num2; break;
      case '/': 
        result = num2 !== 0 ? num1 / num2 : 0; // Return 0 for division by zero
        break;
      default: result = 0;
    }
    return Number.isInteger(result) ? result : Number(result.toFixed(1));
  }, []);

  const getRandomNumber = useCallback((max) => {
    return roundToWholeNumber(Math.random() * max + 1);
  }, []);

//  const getRandomOperator = useCallback(() => {
//    const operatorProbability = Math.min(difficulty / 10, 1);
//    const operators = ['+', '-', '*', '/'];
//    if (Math.random() < operatorProbability) {
//      return operators[Math.floor(Math.random() * 2) + 2];
//    } else {
//      return operators[Math.floor(Math.random() * 4)];
//    }
//  }, [difficulty]);

  const isPrime = useCallback((num) => {
    if (num <= 1) return false;
    for (let i = 2; i <= Math.sqrt(num); i++) {
      if (num % i === 0) return false;
    }
    return true;
  }, []);
  

  const generateGameObjects = useCallback(() => {
    let objects;
    const maxNumber = Math.floor(10 + (difficulty * 5));
  
    const generateIncorrectAnswer = (correctAnswer) => {
      const absAnswer = Math.abs(correctAnswer);
      const range = Math.max(5, absAnswer);
      let incorrectAnswer;
      
      if (correctAnswer === 0) {
        // Special case for zero
        incorrectAnswer = Math.floor(Math.random() * 10) + 1;
        if (Math.random() < 0.5) incorrectAnswer *= -1;
      } else if (Number.isInteger(correctAnswer)) {
        incorrectAnswer = correctAnswer + (Math.floor(Math.random() * range) + 1) * (Math.random() < 0.5 ? 1 : -1);
      } else {
        incorrectAnswer = Number((correctAnswer + (Math.random() - 0.5) * range).toFixed(1));
      }
      
      return incorrectAnswer;
    };
  
    switch (selectionStage) {
      case 0:
      case 2:
        objects = Array(4).fill().map(() => ({
          type: 'number',
          value: getRandomNumber(maxNumber)
        }));
        break;
      case 1: {
        const { num1 } = equation;
        let operators = ['+', '-', '*'];
        if (!isPrime(num1)) {
          operators.push('/');
        }
        objects = operators.map(op => ({
          type: 'operator',
          value: op
        }));
        while (objects.length < 4) {
          objects.push({
            type: 'operator',
            value: operators[Math.floor(Math.random() * operators.length)]
          });
        }
      }
      break;
      case 3: {
        const { num1, operator, num2 } = equation;
        const correctAnswer = safeCalculate(num1, operator, num2);
        let answerOptions = [correctAnswer];
    
        // Function to get the last digit of a number
        const getLastDigit = (number) => Math.abs(number % 10);
    
        if (Number.isInteger(correctAnswer) && correctAnswer !== 0) {
            // Option that shares the same last digit as the correct answer
            const lastDigit = getLastDigit(correctAnswer);
            let incorrectOption1;
            do {
                const offset = (Math.random() < 0.5 ? 1 : -1) * (Math.floor(Math.random() * 9) + 1) * 10; // Random multiple of 10
                incorrectOption1 = correctAnswer + offset;
            } while (getLastDigit(incorrectOption1) !== lastDigit); // Ensure it has the same last digit
            answerOptions.push(incorrectOption1);
    
            // For multiplication (*), generate random multiples of 10 away
            if (operator === '*') {
                const randomMultiplier1 = Math.floor(Math.random() * 9) + 1; // Random number between 1 and 9
                const incorrectOption2 = correctAnswer + (Math.random() < 0.5 ? 10 : -10) * randomMultiplier1;
                answerOptions.push(incorrectOption2);
    
                const randomMultiplier2 = Math.floor(Math.random() * 9) + 1; // Random number between 1 and 9
                const incorrectOption3 = correctAnswer + (Math.random() < 0.5 ? 10 : -10) * randomMultiplier2;
                answerOptions.push(incorrectOption3);
            } else {
                // For other operators, generate options that are in close range
                const range = 20; // Adjust this range as needed
                for (let i = 0; i < 2; i++) { // Generate 2 additional incorrect options
                    let offset = (Math.random() < 0.5 ? 1 : -1) * (Math.floor(Math.random() * range) + 1);
                    const incorrectOption = correctAnswer + offset;
                    answerOptions.push(incorrectOption);
                }
            }
        }
    
  
      // Ensure all answers are unique and not equal to the correct answer
      answerOptions = [...new Set(answerOptions)];
      while (answerOptions.length < 4) {
          const newIncorrect = generateIncorrectAnswer(correctAnswer);
          if (!answerOptions.includes(newIncorrect) && newIncorrect !== correctAnswer) {
              answerOptions.push(newIncorrect);
          }
      }
  
        // Ensure all answers are unique and not equal to the correct answer
        answerOptions = [...new Set(answerOptions)];
        while (answerOptions.length < 4) {
          const newIncorrect = generateIncorrectAnswer(correctAnswer);
          if (!answerOptions.includes(newIncorrect) && newIncorrect !== correctAnswer) {
            answerOptions.push(newIncorrect);
          }
        }
  
        // Shuffle the answer options
        answerOptions = answerOptions.sort(() => Math.random() - 0.5);
  
        objects = answerOptions.map(value => ({
          type: 'number',
          value: value
        }));
      }
      break;
      default:
        objects = [];
    }
    
    const shuffledColumns = [...columns].sort(() => Math.random() - 0.5);
    return objects.map((obj, index) => ({
      ...obj,
      x: shuffledColumns[index],
      y: -gameDimensions.height * 0.1 // 10% above the top of the screen
    }));
  }, [selectionStage, equation, difficulty, columns, gameDimensions.height, safeCalculate, getRandomNumber, isPrime]);

  const calculateScore = useCallback((selectedAnswer) => {
    const { num1, operator, num2 } = equation;
    const correctAnswer = safeCalculate(num1, operator, num2);
    const isCorrect = Math.abs(selectedAnswer - correctAnswer) < 0.01;
    if (isCorrect) {
      // Use Math.abs() to ensure we always add a positive value to the score
      const points = Math.abs(correctAnswer);
      setScore(prevScore => prevScore + Math.round(points));
      logEvent('Game', 'CorrectAnswer', { score: score + Math.round(points) });

    } else {
      setGameOver(true);
      logEvent('Game', 'End', { score: score });
      setLastEquation({ 
        num1, 
        operator, 
        num2, 
        correctAnswer, 
        userAnswer: selectedAnswer 
      });
    }
    setEquation({ num1: null, operator: null, num2: null, answer: null });
    return isCorrect;
  }, [equation, safeCalculate, score]);

  const playBackgroundMusic = useCallback(() => {
    if (!isMusicPlaying.current && backgroundMusicRef.current && audioContext.current) {
      const source = audioContext.current.createBufferSource();
      source.buffer = backgroundMusicRef.current;
      source.connect(audioContext.current.destination);
      source.loop = true;
      source.start();
      isMusicPlaying.current = true;
    }
  }, []);
  

  const playSound = useCallback((soundRef, volume) => {
    if (soundRef.current && audioContext.current) {
      const source = audioContext.current.createBufferSource();
      const gainNode = audioContext.current.createGain();
      
      source.buffer = soundRef.current;
      source.connect(gainNode);
      gainNode.connect(audioContext.current.destination);
      
      gainNode.gain.value = 1.5*volume; // Adjust volume level here
      
      source.start();
    }
  }, []);

  const handleSelection = useCallback((value) => {
    switch (selectionStage) {
      case 0:
        setEquation(prev => ({ ...prev, num1: roundToWholeNumber(value) }));
        playSound(tapSoundRef, 2); // Increase volume for tap sound
        break;
      case 1:
        setEquation(prev => ({ ...prev, operator: value }));
        playSound(tapSoundRef, 2); // Increase volume for tap sound
        break;
      case 2:
        setEquation(prev => ({ ...prev, num2: roundToWholeNumber(value) }));
        playSound(tapSoundRef, 2); // Increase volume for tap sound
        break;
      case 3:
        setEquation(prev => ({ ...prev, answer: value }));
        const isCorrect = calculateScore(value);
        playSound(isCorrect ? correctSoundRef : wrongSoundRef, 1);
        break;
      default:
        console.error('Unexpected selectionStage:', selectionStage);
    }
    setSelectionStage((prevStage) => (prevStage + 1) % 4);
    setGameObjects([]);
  }, [selectionStage, calculateScore, playSound]);

  const updateGameObjects = useCallback((deltaTime) => {
    if (gameOver) {
      return;
    }
    setGameObjects(prevObjects => {
      if (prevObjects.length === 0) {
        return generateGameObjects();
      }
      return prevObjects.map(obj => ({ ...obj, y: obj.y + moveSpeed * (deltaTime / 16) }));
    });
  
    const playerY = gameDimensions.height * PLAYER_RELATIVE_Y;
    const playerHeight = SPRITE_HEIGHT * SPRITE_SCALE;
    const collisionThreshold = gameDimensions.height * COLLISION_THRESHOLD;
  
    const collidedObject = gameObjects.find(obj => {
      const objectWidth = gameDimensions.width * 0.125;
      const objectHeight = gameDimensions.width * 0.125;
  
      // Calculate the center of the player
      const playerCenter = (playerPosition / 100) * gameDimensions.width;
      
      // Calculate the boundaries of the object
      const objectLeft = (obj.x / 100) * gameDimensions.width - objectWidth / 2;
      const objectRight = objectLeft + objectWidth;
      const objectTop = obj.y;
      const objectBottom = objectTop + objectHeight;
  
      // Check if the object has passed the collision threshold
      const isPastThreshold = objectBottom >= collisionThreshold;
  
      // Check for overlap, using the player's center for horizontal detection
      return isPastThreshold && (
        playerCenter >= objectLeft &&
        playerCenter <= objectRight &&
        playerY < objectBottom &&
        (playerY + playerHeight) > objectTop
      );
    });
  
    if (collidedObject) {
      console.log('Collision detected with:', collidedObject.value, 'at x:', collidedObject.x, 'y:', collidedObject.y);
      handleSelection(collidedObject.value);
      setGameObjects(prevObjects => prevObjects.filter(obj => obj !== collidedObject));
    } else if (gameObjects.some(obj => obj.y > gameDimensions.height)) {
      console.log('Object missed, game over');
      setGameOver(true);
      logEvent('Game', 'End', { score: score });
    }
  }, [gameObjects, playerPosition, moveSpeed, generateGameObjects, handleSelection, gameDimensions, gameOver, score]);
  const gameLoop = useCallback((currentTime) => {
    if (gameOver) {
      // If the game is over, don't continue the loop
      return;
    }
  
    if (lastUpdateTimeRef.current === 0) {
      lastUpdateTimeRef.current = currentTime;
    }
    const deltaTime = currentTime - lastUpdateTimeRef.current;
    lastUpdateTimeRef.current = currentTime;
  
    updateGameObjects(deltaTime);
    //setGameTime(prevTime => prevTime + deltaTime / 1000);
  
    animationFrameRef.current = requestAnimationFrame(gameLoop);
  }, [updateGameObjects, gameOver]);

  const updateSpriteFrame = useCallback(() => {
    setSpriteFrame((prevFrame) => (prevFrame + 1) % NUM_FRAMES);
  }, [NUM_FRAMES]);

  useEffect(() => {
    const spriteInterval = setInterval(updateSpriteFrame, 200);
    return () => clearInterval(spriteInterval);
  }, [updateSpriteFrame]);

  useEffect(() => {
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();

    const loadAudio = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return await audioContext.current.decodeAudioData(arrayBuffer);
    };

    Promise.all([
      loadAudio(backgroundMusic),
      loadAudio(tapSound),
      loadAudio(correctSound),
      loadAudio(wrongSound)
    ]).then(([bgMusic, tap, correct, wrong]) => {
      backgroundMusicRef.current = bgMusic;
      tapSoundRef.current = tap;
      correctSoundRef.current = correct;
      wrongSoundRef.current = wrong;

      playBackgroundMusic();
    });

    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, [playBackgroundMusic]);

  useEffect(() => {
    if (!gameOver) {
      animationFrameRef.current = requestAnimationFrame(gameLoop);
    }
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [gameLoop, gameOver]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        setKeys(prev => ({ ...prev, left: true }));
      } else if (e.key === 'ArrowRight') {
        setKeys(prev => ({ ...prev, right: true }));
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'ArrowLeft') {
        setKeys(prev => ({ ...prev, left: false }));
      } else if (e.key === 'ArrowRight') {
        setKeys(prev => ({ ...prev, right: false }));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const movePlayer = () => {
      setPlayerPosition(prev => {
        let newPosition = prev;
        if (keys.left) newPosition -= 2;
        if (keys.right) newPosition += 2;
        return Math.max(0, Math.min(100, newPosition));
      });
    };

    const movementInterval = setInterval(movePlayer, 16);
    return () => clearInterval(movementInterval);
  }, [keys]);

  useEffect(() => {
    const speedIncreaseInterval = setInterval(() => {
      setMoveSpeed(prevSpeed => Math.min(prevSpeed + 0.2, 8));
      setDifficulty(prevDifficulty => Math.min(prevDifficulty + 0.5, 10));
    }, 15000);

    return () => clearInterval(speedIncreaseInterval);
  }, []);

  const preventDefault = (e) => {
    e.preventDefault();
  };
  
  useEffect(() => {
    // Prevent default touch behavior
    document.addEventListener('touchmove', preventDefault, { passive: false });
    
    return () => {
      document.removeEventListener('touchmove', preventDefault);
    };
  }, []);
  
  // Update your touch event handlers
  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    touchStartX.current = e.touches[0].clientX;
    
    if (!isMusicPlaying.current) {
      playBackgroundMusic();
    }
  }, [playBackgroundMusic]);
  
  const handleTouchMove = useCallback((e) => {
    e.preventDefault();
    if (touchStartX.current !== null) {
      const touchX = e.touches[0].clientX;
      const deltaX = touchX - touchStartX.current;
      const gameAreaWidth = gameAreaRef.current.offsetWidth;
      const movementPercentage = (deltaX / gameAreaWidth) * 100;
      
      setPlayerPosition(prev => {
        const newPosition = prev + movementPercentage;
        return Math.max(0, Math.min(100, newPosition));
      });
      
      touchStartX.current = touchX;
    }
  }, []);
  
  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    touchStartX.current = null;
  }, []);


  const resetGame = () => {
    setScore(0);
    setGameOver(false);
    //setGameTime(0);
    setMoveSpeed(2);
    setDifficulty(1);
    setSelectionStage(0);
    setGameObjects([]);
    setEquation({ num1: null, operator: null, num2: null, answer: null });
    setLastEquation({ num1: null, operator: null, num2: null, correctAnswer: null, userAnswer: null });
    setPlayerPosition(50);
    lastUpdateTimeRef.current = 0;
    animationFrameRef.current = requestAnimationFrame(gameLoop);
    logEvent('Game', 'Start');
  };
  
  if (gameOver) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-blue-100 p-4">
        <h1 className="text-4xl font-bold mb-4 joystix-font">Game Over!</h1>
        <p className="text-2xl mb-4 joystix-font">Your score: {score}</p>
        {lastEquation.num1 !== null && (
          <div className="text-sm text-gray-600 text-center mb-4 joystix-font">
            <p>Last equation: {lastEquation.num1} {lastEquation.operator} {lastEquation.num2}</p>
            <p>Correct answer: {formatNumber(lastEquation.correctAnswer)}</p>
            <p>Your answer: {formatNumber(lastEquation.userAnswer)}</p>
          </div>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded joystix-font"
          onClick={resetGame}
        >
          Play Again
        </button>
        {/* <div className="w-full max-w-md mt-4">
          <GoogleAd adSlot="1689424259" />
        </div> */}
      </div>
    );
  }

  const displayEquation = () => {
    if (equation.num1 !== null) {
      let display = `${equation.num1}`;
      if (equation.operator) {
        display += `${equation.operator}`;
        if (equation.num2 !== null) {
          display += `${equation.num2}`;
        }
      }
      return display;
    }
    return '';
  };


  return (
<div className={`fixed inset-0 flex items-center justify-center bg-green-300 overflow-hidden ${isDesktop ? 'p-4' : ''}`}>
      <div 
        className="relative bg-[#90EE90] overflow-hidden"
        style={{
          width: `${gameDimensions.width}px`,
          height: `${gameDimensions.height}px`,
          maxWidth: '100vw',
          maxHeight: '100vh'
        }}
      >
        {/* Score and Equation Box */}
        <div 
          className="bg-black text-white p-2 flex flex-col items-center justify-between joystix-font"
          style={{ 
            height: isDesktop ? '15%' : '20%',
            border: '4px solid yellow', // Yellow border all around the header
          }}
        >
          <h1 className="text-3xl font-bold mb-1 flex items-center justify-center" style={{ fontSize: 'min(6vw, 20px)' }}>
            Maths Run
            <a href="mailto:hey@neer.is" className="ml-2">
              <img src={emailIcon} alt="Email" className="w-6 h-6" />
            </a>
            <a href="https://x.com/@thisisneer" target="_blank" rel="noopener noreferrer" className="ml-2">
              <img src={twitterIcon} alt="Twitter" className="w-6 h-6" />
            </a>
          </h1>
          <div className="flex justify-between w-full px-2">
            <div className="text-left">
              <p className="text-sm">SCORE</p>
              <p className="text-2xl">{score.toString().padStart(4, '0')}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-yellow-400">EQUATION</p>
              <p className="text-2xl">{displayEquation()}</p>
            </div>
          </div>
        </div>
        {/* Game Area */}
        <div 
          ref={gameAreaRef}
          className="relative flex-grow overflow-hidden bg-[#FF9F7F]"
          style={{ 
            height: '85%',
            border: '4px solid white',
            borderTop: 'none',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <ScrollingTrackBackground width="100%" height="100%" />
          <TrackLines />
          {gameObjects.map((obj, index) => (
            <div
              key={index}
              className={`absolute flex items-center justify-center text-2xl font-bold text-white number-circle ${
                (obj.type === 'number' && selectionStage !== 3) ? 'bg-green-500' : 'bg-yellow-500'
              }`}
              style={{ 
                left: `${obj.x}%`, 
                top: `${obj.y}px`, 
                transform: 'translate(-50%, -50%)',
                width: `${gameDimensions.width * 0.18}px`,
                height: `${gameDimensions.width * 0.18}px`,
                borderRadius: '50%',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
            {typeof obj.value === 'number' 
              ? (Number.isInteger(obj.value) ? obj.value : obj.value.toFixed(1))
              : obj.value}
            </div>
          ))}
          {/* Player character */}
          <div
            className="absolute overflow-hidden"
            style={{ 
              left: `${playerPosition}%`, 
              bottom: '20px',
              transform: 'translateX(-50%)',
              width: `${SPRITE_WIDTH * SPRITE_SCALE}px`,
              height: `${SPRITE_HEIGHT * SPRITE_SCALE}px`,
            }}
          >
            <div
              style={{
                width: `${SPRITE_WIDTH * SPRITE_SCALE}px`,
                height: `${SPRITE_HEIGHT * SPRITE_SCALE}px`,
                backgroundImage: `url(${playerSpriteSheet})`,
                backgroundPosition: `-${spriteFrame * SPRITE_WIDTH * SPRITE_SCALE}px -${BOTTOM_ROW_Y * SPRITE_SCALE}px`,
                backgroundSize: `${SPRITE_WIDTH * NUM_FRAMES * SPRITE_SCALE}px ${SPRITE_HEIGHT * 4 * SPRITE_SCALE}px`,
                imageRendering: 'pixelated',
                transition: 'left 0.1s ease-out',
              }}
            />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default MathRunnerGame;