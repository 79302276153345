import React, { useRef, useEffect } from 'react';

const ScrollingTrackBackground = ({ width = 320, height = 480 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    const trackWidth = width * 0.8;
    const grassWidth = (width - trackWidth) / 2;
    const laneWidth = trackWidth / 4;

    let scrollPosition = 0;
    const scrollSpeed = 2;

    const drawTrack = (yOffset) => {
      // Draw grass
      ctx.fillStyle = '#90EE90';
      ctx.fillRect(0, yOffset, grassWidth, height);
      ctx.fillRect(width - grassWidth, yOffset, grassWidth, height);

      // Draw track
      ctx.fillStyle = '#FF7F50';
      ctx.fillRect(grassWidth, yOffset, trackWidth, height);

      // Draw lane lines
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = 2;
      for (let i = 1; i < 4; i++) {
        const x = grassWidth + i * laneWidth;
        ctx.beginPath();
        ctx.setLineDash([10, 10]);
        ctx.moveTo(x, yOffset);
        ctx.lineTo(x, yOffset + height);
        ctx.stroke();
      }

      // Draw outer lane lines
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.moveTo(grassWidth, yOffset);
      ctx.lineTo(grassWidth, yOffset + height);
      ctx.moveTo(width - grassWidth, yOffset);
      ctx.lineTo(width - grassWidth, yOffset + height);
      ctx.stroke();
    };

    const animate = () => {
      ctx.clearRect(0, 0, width, height);
      drawTrack(scrollPosition);
      drawTrack(scrollPosition - height);

      scrollPosition += scrollSpeed;
      if (scrollPosition >= height) {
        scrollPosition = 0;
      }

      requestAnimationFrame(animate);
    };

    animate();
  }, [width, height]);

  return <canvas ref={canvasRef} className="absolute top-0 left-0 pixelated" />;
};

export default ScrollingTrackBackground;